import React, { useEffect, useContext, useState } from "react";
import styles from "./AddMetricDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { METRIC_FIELDS } from "../../utils/fragments";
import { GET_METRICS, GET_ONE_YEAR_METRICS, GET_ONE_YEAR_PLAN, GET_THREE_YEAR_METRICS, GET_WEEKLY_TARGETS } from "../../utils/query";
import { getYear } from "date-fns";
import { year } from "../../utils/dates";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  Chip,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";

import Icon from "@mdi/react";
import { mdiClose, mdiLinkVariant, mdiRefresh } from "@mdi/js";
import { blueGrey, deepPurple, red } from "@material-ui/core/colors";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import Loading from "../Loading/Loading";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import OneYearMetricsList from "../Metrics/OneYearMetricsList";
import Distribution from "../Metrics/Distribution";
import ThreeYearMetricsList from "../Metrics/ThreeYearMetricsList";
import WeeklyTargetsList from "../Metrics/WeeklyTargetsList";
import NumberFormatCustom from "../NumberFormat/NumberFormat";

const initForm = {
  value: null,
  users: [],
  cumulative: false,
  calculateTotal: true,
  year: [
    [{ value: "Y1" }, { value: "" }, { value: "" }],
    [{ value: "Y2" }, { value: "" }, { value: "" }],
    [{ value: "Y3" }, { value: "" }, { value: "" }],
  ],
  quarter: [
    [{ value: "Q1" }, { value: "" }, { value: "" }],
    [{ value: "Q2" }, { value: "" }, { value: "" }],
    [{ value: "Q3" }, { value: "" }, { value: "" }],
    [{ value: "Q4" }, { value: "" }, { value: "" }],
    [{ value: "Total" }, { value: "" }, { value: "" }],
  ],
  unit: null,
  plan: null,
  comparator: "gte",
  enableFormula: false,
  formula: null,
  formulaScope: [],
  objectives: [],
  weeklyTarget: null,
};

const initErrorForm = {
  value: ["required"],
  formula: [
    {
      type: "custom",
      callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
      errorMessage: "This field is required",
    },
  ],
  ...initForm.year.reduce((accum, curr, currIdx) => {
    const numberValidation = [
      {
        type: "custom",
        callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
        errorMessage: "Invalid number formatting",
      },
    ];

    accum[`year[${currIdx}][1].value`] = numberValidation;
    accum[`year[${currIdx}][2].value`] = numberValidation;

    return accum;
  }, {}),
  ...initForm.quarter.reduce((accum, curr, currIdx) => {
    const numberValidation = [
      {
        type: "custom",
        callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
        errorMessage: "Invalid number formatting",
      },
    ];

    accum[`quarter[${currIdx}][1].value`] = numberValidation;
    accum[`quarter[${currIdx}][2].value`] = numberValidation;

    return accum;
  }, {}),
};

const AddMetricDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const { user } = useContext(UserContext);
  const category = _.get(dialog, "addMetricDialog.category", null);
  const oneYearCorpPlan = _.get(dialog, "addMetricDialog.planId"); // this will be 3 year corp plan if creating a 3 year metric
  const yearOne = parseInt(_.get(dialog, "addMetricDialog.yearOne"));
  const YEARS = { Y1: getYear(yearOne), Y2: getYear(yearOne) + 1, Y3: getYear(yearOne) + 2 };

  const [oneYearMetrics, setOneYearMetrics] = useState([]);
  const [oneYearMetricsListOpen, setOneYearMetricsListOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMeasurableIdx, setSelectedMeasurableIdx] = useState();
  const [distributionOpen, setDistributionOpen] = useState(false);
  const [selectedThreeYearMetricDistributionOpen, setSelectedThreeYearMetricDistributionOpen] = useState(false);
  const [selectedThreeYearMetricDistribution,setSelectedThreeYearMetricDistribution] = useState([]);   
  const [threeYearMetrics, setThreeYearMetrics] = useState([]);
  const [threeYearMetricsListOpen, setThreeYearMetricsListOpen] = useState(false);
  const [weeklyTargets, setWeeklyTargets] = useState([]);
  const [weeklyTargetsListOpen, setWeeklyTargetsListOpen] = useState(false);

  const emptyQuarterMeasurables = [
    [{ value: "Q1" }, { value: "" }, { value: "" }],
    [{ value: "Q2" }, { value: "" }, { value: "" }],
    [{ value: "Q3" }, { value: "" }, { value: "" }],
    [{ value: "Q4" }, { value: "" }, { value: "" }],
    [{ value: "Total" }, { value: "" }, { value: "" }],
  ];

  //Only used while creating a "quarter" (1 Year) Metric
  const [measurables, setMeasurables] = useState(emptyQuarterMeasurables);

  const { sharedPlanId } = _.get(user, "departmentFilter");
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_DATA, {
    variables: {
      organization: params.org,
      planCategory: { year: "3 year", quarter: "1 year" }[category],
      category,
      sharedPlanId,
      oneYearCorpPlan,
      closed: _.isNil(oneYearCorpPlan) ? false : null,
    },
  });

  const [getOneYearMetrics, { data: oneYearMetricsData }] = useLazyQuery(GET_ONE_YEAR_METRICS);
  const [getOneYearPlan, { data: oneYearPlanData, refetch: oneYearPlansRefetch }] = useLazyQuery(GET_ONE_YEAR_PLAN);
  const [getThreeYearMetrics, { data: threeYearMetricsData, refetch: threeYearMetricsRefetch }] = useLazyQuery(GET_THREE_YEAR_METRICS);
  const [getWeeklyTargets, { data: weeklyTargetsData }] = useLazyQuery(GET_WEEKLY_TARGETS);
  const [refetchThreeYearMetrics, setRefetchThreeYearMetrics] = useState(false);

  useEffect(() => {
    if (category === "year") {
      getOneYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
    }
    else {
      getOneYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
          corpPlan: oneYearCorpPlan
        },
      });      
    }
    
    
  }, [category]);

  useEffect(() => {
    if (oneYearMetricsData) {
      setOneYearMetrics(_.get(oneYearMetricsData, "metrics", []));
    }
  }, [oneYearMetricsData]);

  const corpPlanId = _.get(dialog, "addMetricDialog.planId");
  const longestMetricMeasurable = _.get(data, "longestMetricMeasurable"); //for 3 year only

  const adjustedInitForm = formatInitialForm(initForm, longestMetricMeasurable, category);

  const variables = _.get(dialog, "addMetricDialog.variables");
  const [updateMetric] = useMutation(UPDATE_METRIC);
  const [createMetric, { loading: mutationLoading }] = useMutation(CREATE_METRIC, {
    update(cache, { data: { createMetric } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const { metric, oneYearMetric } = createMetric;
        let variablesArr1 = [variables];
        if (_.get(metric, "category") === "quarter") {
          // update the metric list
          const existingData = cache.readQuery({
            query: GET_ONE_YEAR_METRICS,
            variables: {
              organization: params.org,
              sharedPlanId,
            },
          });
          if (existingData) {
            cache.writeQuery({
              query: GET_ONE_YEAR_METRICS,
              variables: {
                organization: params.org,
                sharedPlanId,
              },
              data: {
                metrics: [metric, ...existingData.metrics],
              },
            });
          }
        }
        if (!(_.get(metric, "category") === "quarter" && category === "year")) {
          const departmentSharedPlanId = variables.sharedPlanId;
          if (_.isNil(departmentSharedPlanId)) {
            variablesArr1.push({
              organization: variables.organization,
              planId: variables.planId,
              sharedPlanId: _.get(metric, "plan.sharedPlanId"),
            });
          } else {
            variablesArr1.push({ organization: variables.organization, planId: variables.planId, sharedPlanId: null });
          }
          for (const variables of variablesArr1) {
            const existingData = cache.readQuery({
              query: GET_METRICS,
              variables,
            });
            if (existingData) {
              cache.writeQuery({
                query: GET_METRICS,
                variables,
                data: {
                  metrics: [metric, ...existingData.metrics],
                },
              });
            }
          }

          if (oneYearMetric) {
            // find corpPlan variable
            const corpPlanId = _.get(oneYearMetric, "plan.plan.id")
              ? _.get(oneYearMetric, "plan.plan.id")
              : _.get(oneYearMetric, "plan.id");
            const departmentSharedPlanId = _.get(oneYearMetric, "plan.sharedPlanId");

            const variablesArr2 = [
              { organization: variables.organization, planId: corpPlanId, sharedPlanId: null }, // All department query
              { organization: variables.organization, planId: corpPlanId, sharedPlanId: departmentSharedPlanId }, // Specific department query
            ];

            for (const oneYearGetMetricVariable of variablesArr2) {
              const existingOneYearMetricData = cache.readQuery({
                query: GET_METRICS,
                variables: oneYearGetMetricVariable,
              });
              // only write to cache if the one year query exist
              if (existingOneYearMetricData) {
                cache.writeQuery({
                  query: GET_METRICS,
                  variables: oneYearGetMetricVariable,
                  data: {
                    metrics: [oneYearMetric, ...existingOneYearMetricData.metrics],
                  },
                });
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const {
    form,
    formErrors,
    handleChange,
    handleChangeManual,
    handleChangeToggleButton,
    handleToggleCheckBox,
    handleChangeCallback,
    resetForm,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm: adjustedInitForm,
    initErrorForm,
  });

  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  // syntax dialog states
  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);

  const metrics = (_.get(data, "metrics") || []).filter((metric) => {
    // filter by corp plan selection
    if (corpPlanId) {
      const metricCorpPlanId = _.get(metric, "plan.plan.id", _.get(metric, "plan.id"));
      return metricCorpPlanId === corpPlanId;
    }
    return true;
  });
  const metricsById = _.keyBy(metrics, "id");

  const fs = useMediaQuery("(max-width: 600px)");

  const updateTotal = (clonedForm) => {
    const { calculateTotal, quarter } = clonedForm;
    if (calculateTotal) {
      const value = quarter.reduce((sum, [name, val]) => (name.value !== "Total" ? sum + Number(removeFormattedNum(val.value)) : sum), 0);
      quarter[4][1].value = value.toString();
      resetForm({ ...form, quarter });
    }
  };

  const handleComparator = (event, newComparator) => {
    // enforce value set
    if (newComparator !== null) {
      handleChangeManual({ name: "comparator", value: newComparator });
    }
  };

  const handleChangeAuto = () => {
    resetForm({ ...form, calculateTotal: !form.calculateTotal });
  };

  const handleClose = () => {
    resetForm();
    setMeasurables(emptyQuarterMeasurables);
    setDialog({ ...dialog, addMetricDialog: { open: false } });
  };

  const handleOpenOneYearMetrics = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setOneYearMetricsListOpen(true);
  };

  const handleLinkOneYearMetric = (metricId) => {
    const measurableClone = _.cloneDeep(_.get(form, ["year", selectedMeasurableIdx.toString(), "2"]));
    const newMeasurable = _.merge({}, measurableClone, { reference: metricId });

    handleChangeManual({ name: `year[${selectedMeasurableIdx}][2]`, value: newMeasurable });
    setOneYearMetricsListOpen(false);
  };

  const handleOpenThreeYearMetricsList = () => {
    setThreeYearMetricsListOpen(true);
    const plans = _.get(data, "plans");
    const selectedPlan = _.find(plans, (plan) => plan.id === form.plan);
    if (selectedPlan) {
      const planYear = year(_.get(selectedPlan, "year"));
      setSelectedYear(planYear);
    }
  };

  const handleLinkThreeYearMetric = (metricId) => {
    handleChangeManual({ name: "threeYearMetric", value: metricId });
    setThreeYearMetricsListOpen(false);
  };

  const handleOpenDistribution = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setDistributionOpen(true);
  };

  const handleOpenDistributionSelectedThreeYearMetric = () => () => {
    setSelectedThreeYearMetricDistributionOpen(true);
  };
  
  const selectedThreeYearMetric = threeYearMetrics.find((m) => m.id === form.threeYearMetric);
  const selectedThreeYearMetricYearIndex = _.get(selectedThreeYearMetric, "measurables", []).findIndex((year, index) => {
    //the "year" value below might be an actual year, or it could be "Y1", "Y2", etc.
    const value = _.get(year, "0.value");
    const selectedYear = getYear(yearOne); //the actual year from the active oneYearPlan
    return selectedYear === parseInt(value) || value === _.findKey(YEARS, (yValue) => {
      return yValue === selectedYear
    });
  });
  const selectedThreeYearMetricValue = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "value"])
  
  useEffect(() => {
    const selectedThreeYearMetric = threeYearMetrics.find((m) => m.id === form.threeYearMetric);
    const selectedThreeYearMetricYearIndex = _.get(selectedThreeYearMetric, "measurables", []).findIndex((year, index) => {
      //the "year" value below might be an actual year, or it could be "Y1", "Y2", etc.
      const value = _.get(year, "0.value");
      const selectedYear = getYear(yearOne); //the actual year from the active oneYearPlan
      return selectedYear === parseInt(value) || value === _.findKey(YEARS, (yValue) => {
        return yValue === selectedYear
      });
    });

    const newDistribution = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "distribution"],[]);
    if (!_.isNil(newDistribution) && !_.isEqual(newDistribution, selectedThreeYearMetricDistribution)) {
      setSelectedThreeYearMetricDistribution(newDistribution);
    }

    if(!_.isNil(form.threeYearMetric)) {
      //turn on Auto button, and disable it!
      resetForm({ ...form, calculateTotal: true });
    }

  }, [form.threeYearMetric, threeYearMetrics]);

  useEffect(() => {
    if (category === "quarter") {
        const newMeasurables = measurables.map((measurable, idx) => {
        const newMeasurable = _.cloneDeep(measurable);
        if (!_.isNil(selectedThreeYearMetricDistribution[idx])) {
          _.set(newMeasurable, ["1", "value"], _.round(selectedThreeYearMetricValue * selectedThreeYearMetricDistribution[idx], 10).toString());
        }
        return newMeasurable;
      });
      setMeasurables([...newMeasurables]);
    }

}, [selectedThreeYearMetricDistribution]); 

  const handleChangeDistributionSelectedThreeYearMetric = async (distribution) => {
    if (!_.isEqual(distribution, selectedThreeYearMetricDistribution)) {
      setSelectedThreeYearMetricDistribution(distribution);
    }

    setSelectedThreeYearMetricDistributionOpen(false);    
  }

  const handleChangeDistribution = (distribution) => {
    const measurableClone = _.cloneDeep(_.get(form, ["year", selectedMeasurableIdx.toString(), "1"]));
    const newMeasurable = _.merge({}, measurableClone, { distribution });

    handleChangeManual({ name: `year[${selectedMeasurableIdx}][1]`, value: newMeasurable });
    setDistributionOpen(false);
  };

  const handleLinkWeeklyTarget = (wtId) => {
    handleChangeManual({ name: "weeklyTarget", value: wtId });
    setWeeklyTargetsListOpen(false);
  };

  const handleCreateQuarterMetric = async () => {
    const plans = _.get(data, "plans", []);
    const threeYearPlan = _.find(plans, { id: form.plan });

    if (!validateForm()) return;

    const { value, unit, users, objectives, cumulative, calculateTotal, comparator, enableFormula, formula, formulaScope, weeklyTarget } =
      form;

    let measurables = [];

    const { quarter } = form;
    measurables = quarter;

    measurables.forEach((m) => {
      m[1].value = removeFormattedNum(m[1].value);
    });

    await createMetric({
      variables: {
        organization: params.org,
        plan: _.get(oneYearPlanData, "plan.id"),
        category: "quarter",
        value,
        users,
        comparator,
        measurables,
        unit,
        weeklyTarget,
      },
    });
  };

  const handleSubmit =
    ({ close, category }) =>
    async () => {
      if (!validateForm()) return;

      const {
        value,
        unit,
        users,
        plan,
        objectives,
        cumulative,
        calculateTotal,
        comparator,
        enableFormula,
        formula,
        formulaScope,
        weeklyTarget,
        threeYearMetric,
      } = form;

      let measurables = [];
      if (category === "year") measurables = form.year;
      else if (category === "quarter") {
        const { quarter } = form;
        if (cumulative) {
          quarter[4][1].value = quarter[3][1].value.toString(); //Year total is the same as the 4th quarter total
        }
        measurables = quarter;

        if (selectedThreeYearMetric) {
          //Update the distribution in the 3 year metric, if it's changed.
          const orignalDistribution = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "distribution"],[]);
          if (!_.isNil(orignalDistribution) && !_.isEqual(orignalDistribution, selectedThreeYearMetricDistribution)) {
            //if the distribution has been changed by the user, update the selectedThreeYearMetric to reflect this
  
            // remove __typename from the measurable objects
            const measurablesClone = _.cloneDeep(_.get(selectedThreeYearMetric, ["measurables"])).map((array) =>
              array.map((obj) => ({
                value: obj.value,
                notes: obj.notes,
                reference: _.get(obj, "reference.id"),
                distribution: obj.distribution,
              }))
            );
            const newMeasurables = _.set(measurablesClone, [`${selectedThreeYearMetricYearIndex}`, "1", "distribution"], selectedThreeYearMetricDistribution);
  
            try {
              await updateMetric({
                variables: {
                  id: selectedThreeYearMetric.id,
                  measurables: newMeasurables,
                },
              });           
            }   
            catch (e) {
              console.log(e);
            }     
          }
  
        }        
      }

      measurables.forEach((m) => {
        m[1].value = removeFormattedNum(m[1].value);
      });

      const res = await createMetric({
        variables: {
          organization: params.org,
          plan,
          objectives,
          category,
          value,
          users,
          comparator,
          measurables,
          unit,
          cumulative,
          calculateTotal,
          enableFormula,
          formula,
          formulaScope,
          weeklyTarget,
          threeYearMetric,
        },
      });

      if (res) {
        snack(`Created "${value}" metric`);
        // requestFetch();
        resetForm({ ...initForm, plan });
        setMeasurables(emptyQuarterMeasurables);
        if (close) {
          handleClose();
        }
      }
    };

    useEffect(() => {    
      if (!_.isEqual(form.quarter, measurables)) {
        handleChangeManual({ name: `quarter`, value: measurables });
      }

    }, [measurables]);

  useEffect(() => {
    if (category === "quarter") {
      getThreeYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
      getWeeklyTargets({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
    }
  }, [category]);

  useEffect(() => {
    if ((category === "quarter") && (refetchThreeYearMetrics || dialog.addMetricDialog.open)) {
      threeYearMetricsRefetch({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });

      setRefetchThreeYearMetrics(false);
    }
  }, [refetchThreeYearMetrics, dialog.addMetricDialog.open]);


  useEffect(() => {
    const plans = _.get(data, "plans", []);
    const threeYearPlan = _.find(plans, { id: form.plan });
    getOneYearPlan({
      variables: {
        organization: params.org,
        sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
      },
    });
  }, [form.plan]);

  useEffect(() => {
    if (threeYearMetricsData) {
      if (category === "3 year") {
        setThreeYearMetrics(_.get(threeYearMetricsData, "metrics", []));
      }
      else if (oneYearMetrics) {

        const linkedThreeYearIdsToFilterList = oneYearMetrics
        .map(metric => metric.threeYearMetric)  // Extract the threeYearMetric value
        .filter(metricId => metricId && metricId.trim() !== '' && metricId !== form.threeYearMetric);
        
        const allMetrics = _.get(threeYearMetricsData, "metrics", []);
        const filteredMetrics = allMetrics.filter(metric => !linkedThreeYearIdsToFilterList.includes(metric.id));

        setThreeYearMetrics(filteredMetrics);
      }
    }
  }, [threeYearMetricsData, oneYearMetrics, form.threeYearMetric]);





  useEffect(() => {
    if (weeklyTargetsData) {
      setWeeklyTargets(_.get(weeklyTargetsData, "weeklyTargets", []));
    }
  }, [weeklyTargetsData]);

  useEffect(() => {
    if (form.calculateTotal) {
      updateTotal(form);
    }
  }, [form.calculateTotal]);

  const departmentFilterSharedId = _.get(user, "departmentFilter.sharedPlanId");
  useEffect(() => {
    const plans = _.get(data, "plans", []);
    const planWithSameSharedPlanId = _.find(plans, (plan) => plan.sharedPlanId === departmentFilterSharedId);

    const plan = _.get(dialog, "addMetricDialog.planId");
    const users = _.get(dialog, "addMetricDialog.users");
    const unit = _.get(dialog, "addMetricDialog.unit");
    const value = _.get(dialog, "addMetricDialog.value");

    if (dialog.addMetricDialog.open && !queryLoading) {
      resetForm({
        ...adjustedInitForm,
        plan: departmentFilterSharedId ? _.get(planWithSameSharedPlanId, "id", null) : plan,
        ...(!_.isNil(users) && { users }),
        ...(!_.isNil(unit) && { unit }),
        ...(!_.isNil(value) && { value }),
      });
    } else {
      resetForm();
      setMeasurables(emptyQuarterMeasurables);
    }
  }, [dialog.addMetricDialog.open, departmentFilterSharedId, queryLoading]);

  useEffect(() => {
    if (dialog.addMetricDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addMetricDialog.open]);

  if (queryLoading) return null;

  const numOfvariables = _.get(form, "formulaScope.length");
  const plansOrder = _.get(data, "organization.plansOrder", []);

  const selectedWeeklyTarget = weeklyTargets.find((wt) => wt.id === form.weeklyTarget);

  return (
    <Dialog
      open={dialog.addMetricDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Metric
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Metric Details"
          name="value"
          fullWidth
          margin="normal"
          variant="outlined"
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <SelectDepartment
          plans={_.get(data, "plans")}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText="Which department does this metric belong to?"
          disabled={!_.isNil(departmentFilterSharedId)}
        />
        {category === "year" && (
          <SelectObjectives
            name="objectives"
            objectives={_.get(data, "objectives", null)}
            handleChange={handleChange}
            values={form.objectives}
            category={"1 year"}
            multiple
            helperText="Which 3 year objectives are tied to this metric?"
            plansOrder={plansOrder}
            statuses={["on track", "off track"]}
          />
        )}
        {category === "quarter" && (
          <>
            <ToggleButtonGroup
              exclusive
              value={form.cumulative}
              onChange={handleChangeToggleButton("cumulative")}
              className={styles.flexCenter}
            >
              <ToggleButton value={false}>
                <Tooltip title="Each entry is the total from that quarter">
                  <div>Add values</div>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={true}>
                <Tooltip title="Each entry includes the totals of previous quarters">
                  <div>Don't add values</div>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
        <SelectUsers
          name="users"
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          helperText="Who is accountable for this metric?"
          plan={form.plan}
          allPlans={_.get(data, "plans")}
        />
        <div className={styles.modelButtons}>
          <ToggleButtonGroup exclusive value={form.comparator} onChange={handleComparator}>
            <ToggleButton value="lt">
              <span className={styles.toggleText}>&#60;</span>
            </ToggleButton>
            <ToggleButton value="lte">
              <span className={styles.toggleText}>&#8804;</span>
            </ToggleButton>
            <ToggleButton value="eq">
              <span className={styles.toggleText}>=</span>
            </ToggleButton>
            <ToggleButton value="gte">
              <span className={styles.toggleText}>&#8805;</span>
            </ToggleButton>
            <ToggleButton value="gt">
              <span className={styles.toggleText}>&#62;</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <FormControlLabel
          control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
          label="Enable Formula"
          style={{ marginLeft: -7 }}
        />
        {form.enableFormula && (
          <div className={styles.formulaInput} style={{ marginBottom: 8 }}>
            <TextField
              placeholder="Formula (e.g. (v0 + 100)/v2)"
              name="formula"
              fullWidth
              margin="dense"
              variant="filled"
              value={form.formula}
              onChange={handleChange}
              helperText={formErrors.formula}
              error={Boolean(formErrors.formula)}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Add Variable">
                      <IconButton onClick={() => setVariableDialogOpen(true)}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                      <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <div>
              {form.formulaScope.map((scope, idx) => {
                const { varType, value: scopeValue } = scope;
                const displayName = varType === "number" ? scopeValue : _.get(metricsById, `${scopeValue}.value`, "");
                return (
                  <Chip
                    key={idx}
                    label={
                      <span>
                        <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                        {`${displayName}`}
                      </span>
                    }
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onDelete={() => {
                      const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                      handleChange({
                        target: {
                          name: "formulaScope",
                          value: newScope,
                        },
                      });
                    }}
                    onClick={() => {
                      setVariableDialogOpen(true);
                      setFormulaScopeIdx(idx);
                    }}
                  />
                );
              })}
            </div>
            <VariableDialog
              open={variableDialogOpen}
              handleClose={() => {
                setVariableDialogOpen(false);
                setFormulaScopeIdx(null);
              }}
              handleChangeVariable={(value) =>
                handleChange({
                  target: {
                    name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                    value,
                  },
                })
              }
              formulaScope={_.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
                varType: "reference",
                value: null,
              })}
              list={metrics}
              listName={"Metric"}
              createMode={_.isNil(formulaScopeIdx)}
            />
            <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
          </div>
        )}
        {category === "quarter" && !form.enableFormula && (
          <div className={styles.flex}>
            <div className={styles.field}>
              <div className={styles.flex}>

                {!_.isNil(form.threeYearMetric) ? (
                  <>
                    <div className={styles.flex}>
                      <IconButton onClick={handleOpenDistributionSelectedThreeYearMetric()}> 
                        <Icon path={mdiRefresh} size={1} />
                      </IconButton>  
                      <span className={styles.buttonText}>
                        Using <span style={{ fontWeight: "bold" }}>{_.get(selectedThreeYearMetric, "value", "")}</span>{" "}
                      </span>
      
                    </div>                
                  </>
                ) : (
                  <><span className={styles.buttonText}>Use 3 Year Metric</span></>
                )}
                
                {" "}
                <IconButton
                  onClick={!_.isNil(form.threeYearMetric) ? () => handleLinkThreeYearMetric(null) : handleOpenThreeYearMetricsList}
                >
                  <Icon
                    path={!_.isNil(form.threeYearMetric) ? mdiClose : mdiLinkVariant}
                    size={1}
                    color={!_.isNil(form.threeYearMetric) ? red[400] : blueGrey[400]}
                  />
                </IconButton>
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.flex}>
                <span className={styles.buttonText}>
                  {!_.isNil(form.weeklyTarget) ? (
                    <>
                      Using KPI <span style={{ fontWeight: "bold" }}>{_.get(selectedWeeklyTarget, "value", "")}</span>
                    </>
                  ) : (
                    <>Use KPI</>
                  )}
                </span>{" "}
                <IconButton
                  onClick={!_.isNil(form.weeklyTarget) ? () => handleLinkWeeklyTarget(null) : () => setWeeklyTargetsListOpen(true)}
                >
                  <Icon
                    path={!_.isNil(form.weeklyTarget) ? mdiClose : mdiLinkVariant}
                    size={1}
                    color={!_.isNil(form.weeklyTarget) ? red[400] : blueGrey[400]}
                  />
                </IconButton>
              </div>
            </div>
            <span className={styles.textFields}></span>
          </div>
        )}
        {category === "year" && (
          <>
            {form.year.map((year, idx) => {
              const [name, projected, actual] = year;
              const yearNumber = _.get(YEARS, name.value, parseInt(name.value));
              const showLinkBtn = yearNumber <= getYear(yearOne) && !form.enableFormula;
              const reference = _.get(actual, "reference");
              const hasReference = !_.isNil(reference);

              return (
                <div className={styles.metricInput} key={idx}>
                  <TextField
                    label={`${year[0].value.replace("Y", "Year ")} Projected Value`}
                    name={`year[${idx}][1].value`}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={form.enableFormula ? "TBD" : form.year[idx][1].value}
                    onChange={handleChange}
                    disabled={form.enableFormula}
                    helperText={_.get(formErrors, `year[${idx}][1].value`)}
                    error={Boolean(_.get(formErrors, `year[${idx}][1].value`))}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: (
                        <IconButton onClick={handleOpenDistribution(yearNumber, idx)}>
                          <Icon path={mdiRefresh} size={1} />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    label="Unit"
                    name="unit"
                    margin="normal"
                    variant="outlined"
                    value={form.unit || ""}
                    onChange={handleChange}
                    className={styles.unit}
                  />
                  <Tooltip title="Link 1 year metric">
                    <IconButton
                      onClick={handleOpenOneYearMetrics(yearNumber, idx)}
                      style={{ visibility: showLinkBtn ? "visible" : "hidden" }}
                      disabled={!showLinkBtn}
                    >
                      <Icon path={mdiLinkVariant} size={1} color={hasReference ? deepPurple[400] : blueGrey[400]} />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            })}
          </>
        )}

        {category === "quarter" && (
          <>
            {form.quarter.map((quarter, idx) => {
              if (quarter[0].value === "Total" && form.cumulative) {
                return null;
              }
              return (
                <div className={styles.metricInput} key={idx}>
                  {quarter[0].value !== "Total" ? (
                    <TextField
                      label={`Quarter ${idx + 1} Projected Value`}
                      name={`quarter[${idx}][1].value`}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      value={form.enableFormula ? "TBD" : form.quarter[idx][1].value}
                      onChange={handleChangeCallback(updateTotal)}
                      disabled={form.enableFormula || form.threeYearMetric}
                      helperText={_.get(formErrors, `quarter[${idx}][1].value`)}
                      error={Boolean(_.get(formErrors, `quarter[${idx}][1].value`))}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  ) : (
                    <TextField
                      label="Yearly Projected Value"
                      name={`quarter[${idx}][1].value`}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={form.enableFormula ? "TBD" : form.quarter[idx][1].value}
                      onChange={handleChange}
                      disabled={form.calculateTotal || form.enableFormula || form.threeYearMetric}
                      helperText={_.get(formErrors, `quarter[${idx}][1].value`)}
                      error={Boolean(_.get(formErrors, `quarter[${idx}][1].value`))}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <Button
                            disabled={form.threeYearMetric}
                            variant={form.calculateTotal ? "contained" : "outlined"}
                            onClick={handleChangeAuto}
                            color="primary"
                            disableElevation
                          >
                            Auto
                          </Button>
                        ),
                      }}
                    />
                  )}
                  <TextField
                    label="Unit"
                    name="unit"
                    margin="normal"
                    variant="outlined"
                    value={form.unit || ""}
                    onChange={handleChange}
                    className={styles.unit}
                  />
                </div>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit({ close: false, category })}
          color="primary"
          variant="outlined"
          disabled={mutationLoading}
          className={styles.button}
        >
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button
          onClick={handleSubmit({ close: true, category })}
          color="primary"
          variant="contained"
          disabled={mutationLoading}
          className={styles.button}
        >
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
      {oneYearMetricsListOpen && (
        <OneYearMetricsList
          open={oneYearMetricsListOpen}
          onClose={() => setOneYearMetricsListOpen(false)}
          metrics={oneYearMetrics}
          selectedYear={selectedYear}
          closed={selectedYear < getYear(yearOne)}
          onSubmit={handleLinkOneYearMetric}
          formMetric={_.get(form, ["year", selectedMeasurableIdx.toString(), "2", "reference"])}
          plansOrder={plansOrder}
          handleCreateQuarterMetric={handleCreateQuarterMetric}
          disableMessage={_.isNil(form.value) ? "Metric is missing metric detail" : undefined}
        />
      )}
      {distributionOpen && (
        <Distribution
          open={distributionOpen}
          onClose={() => setDistributionOpen(false)}
          selectedYear={selectedYear}
          handleSubmit={handleChangeDistribution}
          distribution={_.get(form, ["year", selectedMeasurableIdx.toString(), "1", "distribution"])}
          projectedTotal={_.get(form, ["year", selectedMeasurableIdx.toString(), "1", "value"])}
        />
      )}
      {selectedThreeYearMetricDistributionOpen && (
        <Distribution
          open={selectedThreeYearMetricDistributionOpen}
          onClose={() => setSelectedThreeYearMetricDistributionOpen(false)}
          selectedYear={getYear(yearOne)}
          handleSubmit={handleChangeDistributionSelectedThreeYearMetric}
          distribution={_.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "distribution"])}
          projectedTotal={_.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "value"])}
        />
      )}        
      {threeYearMetricsListOpen && (
        <ThreeYearMetricsList
          open={threeYearMetricsListOpen}
          onClose={() => setThreeYearMetricsListOpen(false)}
          metrics={threeYearMetrics}
          onSubmit={handleLinkThreeYearMetric}
          formMetric={_.get(form, ["threeYearMetric"])}
          selectedYear={selectedYear}
          updateMetric={updateMetric}
          snack={snack}
        />
      )}
      {weeklyTargetsListOpen && (
        <WeeklyTargetsList
          open={weeklyTargetsListOpen}
          onClose={() => setWeeklyTargetsListOpen(false)}
          weeklyTargets={weeklyTargets}
          onSubmit={handleLinkWeeklyTarget}
          formWeeklyTarget={_.get(form, ["weeklyTarget"])}
        />
      )}
    </Dialog>
  );
};

export default AddMetricDialog;

const GET_DATA = gql`
  query AddMetricDialog_GetData(
    $organization: ID!
    $category: String
    $planCategory: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $closed: Boolean
  ) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      id
      name {
        first
        last
      }
      profilePicture
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $planCategory, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
      year
    }

    metrics(organization: $organization, category: $category, sharedPlanId: $sharedPlanId) {
      id
      value
      measurables {
        value
        notes {
          id
        }
        reference {
          id
        }
        distribution
      }
      unit
      cumulative
      calculateTotal
      enableFormula
      formula
      formulaScope {
        varType
        value
      }
      status
      plan {
        id
        plan {
          id
        }
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      id: _id
      value
    }

    threeYearMetrics: metrics(organization: $organization, sharedPlanId: $sharedPlanId, category: "year") {
      id
      category
      value
    }

    longestMetricMeasurable(org: $organization) {
      measurables {
        value
        notes
        reference
      }
    }

    objectives(organization: $organization, archived: false, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      status
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation CreateOneYearMetric(
    $value: String!
    $comparator: String!
    $measurables: [[MetricMeasurableInput]]
    $unit: String
    $organization: ID!
    $plan: ID!
    $objectives: [ID!]
    $category: String!
    $users: [ID!]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $weeklyTarget: ID
    $threeYearMetric: ID
  ) {
    createMetric(
      value: $value
      comparator: $comparator
      measurables: $measurables
      unit: $unit
      organization: $organization
      plan: $plan
      objectives: $objectives
      category: $category
      users: $users
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      weeklyTarget: $weeklyTarget
      threeYearMetric: $threeYearMetric
    ) {
      metric {
        ...MetricFields
      }
      plans {
        id
        metrics
      }
      oneYearMetric {
        ...MetricFields
      }
      metrics {
        id
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const UPDATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation MetricsAddDialog_UpdateMetric($id: ID!, $measurables: [[MetricMeasurableInput]]) {
    updateMetric(id: $id, measurables: $measurables) {
      metric {
        ...MetricFields
      }
      metrics {
        id
        number
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      plans {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const formatInitialForm = (initForm, longestMetricMeasurable, category) => {
  // add extra year columns
  if (_.isNil(longestMetricMeasurable) || category === "quarter") {
    return initForm;
  }
  let newInitForm = { ...initForm };
  const measurables = longestMetricMeasurable.measurables;
  const measurableLength = measurables.length;

  // if other metric contains more than 3 year columns, add the years to initForm
  let closedMeasurables = [];
  if (measurableLength > 3) {
    measurables.forEach((year, idx) => {
      if (measurableLength - idx > 3) {
        const yearName = year[0].value;
        closedMeasurables.push([{ value: yearName }, { value: "" }, { value: "" }]);
      }
    });

    newInitForm.year = [...closedMeasurables, ...newInitForm.year];
  }

  return newInitForm;
};
