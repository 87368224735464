import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import styles from "./Metrics.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getYear, year } from "../../utils/dates";
import { calcQuarterMetricTotal, getQuarterMetricTotal } from "../../utils/misc";
import { GET_ONE_YEAR_METRICS, GET_ONE_YEAR_PLAN, GET_THREE_YEAR_METRICS, GET_WEEKLY_TARGETS } from "../../utils/query";
import { METRIC_FIELDS } from "../../utils/fragments";

import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tooltip,
  Typography,
  IconButton,
  Switch,
  Chip,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import Icon from "@mdi/react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mdiClose, mdiLinkVariant, mdiRefresh } from "@mdi/js";
import { deepPurple, blueGrey, red } from "@material-ui/core/colors";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import PlanPill from "../PlanPill/PlanPill";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import OneYearMetricsList from "./OneYearMetricsList";
import Distribution from "./Distribution";
import ThreeYearMetricsList from "./ThreeYearMetricsList";
import WeeklyTargetsList from "./WeeklyTargetsList";
import NumberFormatCustom from "../NumberFormat/NumberFormat";

const EditDialog = ({
  open,
  handleClose,
  metric,
  snack,
  category,
  metrics,
  yearOne,
  plansOrder,
  handleOpenAddMetricDialog,
  corpForSelectedYear,
  planId,
}) => {
  const {
    id,
    value,
    comparator,
   // measurables,
    unit,
    users,
    status,
    departmentName,
    plan,
    cumulative,
    calculateTotal,
    enableFormula,
    formula,
    formulaScope,
    number,
    objectives,
    weeklyTarget,
    threeYearMetric,
  } = metric;
  const [measurables, setMeasurables] = useState([...metric.measurables]);

  const YEARS = { Y1: getYear(yearOne), Y2: getYear(yearOne) + 1, Y3: getYear(yearOne) + 2 };

  const { user } = useContext(UserContext);
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const params = useParams();
  const { fetch, requestFetch } = useContext(FetchContext);

  const [updateMetric] = useMutation(UPDATE_METRIC);
  const {
    data,
    refetch,
    loading: dialogDataLoading,
  } = useQuery(GET_DIALOG_DATA, {
    variables: {
      organization: params.org,
      category,
      sharedPlanId,
      oneYearCorpPlan: planId, // 3year plan id if edit 3 year, else 1 year plan id
      closed: _.isNil(corpForSelectedYear) ? false : null,
    },
  });

  const [getOneYearMetrics, { data: oneYearMetricsData }] = useLazyQuery(GET_ONE_YEAR_METRICS);
  const [getOneYearPlan, { data: oneYearPlanData, refetch: oneYearPlansRefetch }] = useLazyQuery(GET_ONE_YEAR_PLAN);
  const [getThreeYearMetrics, { data: threeYearMetricsData, refetch: threeYearMetricsRefetch  }] = useLazyQuery(GET_THREE_YEAR_METRICS);
  const [getWeeklyTargets, { data: weeklyTargetsData }] = useLazyQuery(GET_WEEKLY_TARGETS);

  const initForm = {
    value,
    comparator,
    measurables: measurables.map((array) =>
      array.map((obj) => ({ value: obj.value, notes: obj.notes, reference: _.get(obj, "reference.id"), distribution: obj.distribution }))
    ),
    unit,
    cumulative,
    calculateTotal,
    users: users.map((u) => u.id),
    plan: _.get(plan, "id"),
    enableFormula,
    formula,
    formulaScope: (formulaScope || []).map((variable) => _.omit(variable, "__typename")),
    objectives,
    weeklyTarget,
    threeYearMetric,
  };

  const initErrorForm = {
    value: ["required"],
    formula: [
      {
        type: "custom",
        callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
        errorMessage: "This field is required",
      },
    ],
    ..._.get(initForm, "measurables", []).reduce((accum, curr, currIdx) => {
      const numberValidation = [
        {
          type: "custom",
          callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
          errorMessage: "Invalid number formatting",
        },
      ];

      accum[`measurables[${currIdx}][1].value`] = numberValidation;
      accum[`measurables[${currIdx}][2].value`] = numberValidation;

      return accum;
    }, {}),
  };

  const {
    form,
    formErrors,
    resetForm,
    handleChange,
    handleChangeManual,
    handleChangeToggleButton,
    handleToggleCheckBox,
    handleChangeCallback,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm,
    initErrorForm,
  });

  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);
  const [openAddMetricDialog, setOpenAddMetricDialog] = useState(false);

  const [oneYearMetrics, setOneYearMetrics] = useState([]);
  const [oneYearMetricsListOpen, setOneYearMetricsListOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMeasurableIdx, setSelectedMeasurableIdx] = useState();
  const [distributionOpen, setDistributionOpen] = useState(false);
  const [selectedThreeYearMetricDistributionOpen, setSelectedThreeYearMetricDistributionOpen] = useState(false);
  const [selectedThreeYearMetricDistribution,setSelectedThreeYearMetricDistribution] = useState([]); 
  const [threeYearMetrics, setThreeYearMetrics] = useState([]);
  const [threeYearMetricsListOpen, setThreeYearMetricsListOpen] = useState(false);
  const [weeklyTargets, setWeeklyTargets] = useState([]);
  const [weeklyTargetsListOpen, setWeeklyTargetsListOpen] = useState(false);
  const [refetchThreeYearMetrics, setRefetchThreeYearMetrics] = useState(false);

  const metricsById = _.keyBy(metrics, "id");

  const handleChangeAuto = () => {
    resetForm({ ...form, calculateTotal: !form.calculateTotal });
  };

  const updateTotal = (index) => (clonedForm) => {
    const { calculateTotal, measurables } = clonedForm;
    const clonedMeasurables = _.cloneDeep(measurables);
    if (clonedMeasurables.length === 5 && calculateTotal) {
      const value = calcQuarterMetricTotal(clonedMeasurables, index);
      clonedMeasurables[4][index].value = value.toString();

      if (index === 2) {
        resetForm({ ...form, measurables: clonedMeasurables });
      } else {
        updateTotal(2)({ ...clonedForm, measurables: clonedMeasurables });
      }
    }
  };

  const handleOpenOneYearMetrics = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setOneYearMetricsListOpen(true);
  };

  const handleLinkOneYearMetric = (metricId) => {
    const measurableClone = _.cloneDeep(_.get(form, ["measurables", selectedMeasurableIdx.toString(), "2"]));
    const newMeasurable = _.merge({}, measurableClone, { reference: metricId });
    handleChangeManual({ name: `measurables[${selectedMeasurableIdx}][2]`, value: newMeasurable });
    setOneYearMetricsListOpen(false);
  };

  const handleOpenDistribution = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setDistributionOpen(true);
  };

  const handleOpenDistributionSelectedThreeYearMetric = () => () => {
    setSelectedThreeYearMetricDistributionOpen(true);
  };
  

  const handleOpenThreeYearMetricsList = () => {
    const planYear = year(_.get(plan, "year"));
    setThreeYearMetricsListOpen(true);
    setSelectedYear(planYear);
  };

  const handleLinkThreeYearMetric = (metricId) => {
    handleChangeManual({ name: "threeYearMetric", value: metricId });
    setThreeYearMetricsListOpen(false);
  };

  const handleChangeDistribution = (distribution) => {
    const measurableClone = _.cloneDeep(_.get(form, ["measurables", selectedMeasurableIdx.toString(), "1"]));
    const newMeasurable = _.merge({}, measurableClone, { distribution });

    handleChangeManual({ name: `measurables[${selectedMeasurableIdx}][1]`, value: newMeasurable });
    setDistributionOpen(false);
  };

  const handleLinkWeeklyTarget = (wtId) => {
    handleChangeManual({ name: "weeklyTarget", value: wtId });
    setWeeklyTargetsListOpen(false);
  };

  const selectedThreeYearMetric = threeYearMetrics.find((m) => m.id === form.threeYearMetric);
  const selectedThreeYearMetricYearIndex = _.get(selectedThreeYearMetric, "measurables", []).findIndex((year, index) => {
    //the "year" value below might be an actual year, or it could be "Y1", "Y2", etc.
    const value = _.get(year, "0.value");
    const selectedYear = getYear(yearOne); //the actual year from the active oneYearPlan
    return selectedYear === parseInt(value) || value === _.findKey(YEARS, (yValue) => {
      return yValue === selectedYear
    });
  });
  const selectedThreeYearMetricValue = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "value"])
  
  useEffect(() => {
    const selectedThreeYearMetric = threeYearMetrics.find((m) => m.id === form.threeYearMetric);
    const selectedThreeYearMetricYearIndex = _.get(selectedThreeYearMetric, "measurables", []).findIndex((year, index) => {
      //the "year" value below might be an actual year, or it could be "Y1", "Y2", etc.
      const value = _.get(year, "0.value");
      const selectedYear = getYear(yearOne); //the actual year from the active oneYearPlan
      return selectedYear === parseInt(value) || value === _.findKey(YEARS, (yValue) => {
        return yValue === selectedYear
      });
    });

    const newDistribution = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "distribution"],[]);
    if (!_.isNil(newDistribution) && !_.isEqual(newDistribution, selectedThreeYearMetricDistribution)) {
      setSelectedThreeYearMetricDistribution(newDistribution);
    }
  }, [form.threeYearMetric, threeYearMetrics]);

  useEffect(() => {
    if (category === "1 year") {
        const newMeasurables = measurables.map((measurable, idx) => {
        const newMeasurable = _.cloneDeep(measurable);
        if (!_.isNil(selectedThreeYearMetricDistribution[idx])) {
          _.set(newMeasurable, ["1", "value"], _.round(selectedThreeYearMetricValue * selectedThreeYearMetricDistribution[idx], 10).toString());
        }
        return newMeasurable;
      });
      setMeasurables([...newMeasurables]);
    }

}, [selectedThreeYearMetricDistribution]); 

  const handleChangeDistributionSelectedThreeYearMetric = async (distribution) => {

    if (!_.isEqual(distribution, selectedThreeYearMetricDistribution)) {
      setSelectedThreeYearMetricDistribution(distribution);
    }

    setSelectedThreeYearMetricDistributionOpen(false);    
  }


  const getOneYearMetricTotal = (metricId) => {
    const oneYearMetric = _.find(oneYearMetrics, ["id", metricId]);
    const measurables = _.get(oneYearMetric, "measurables", []);
    const calculateTotal = _.get(oneYearMetric, "calculateTotal", false);

    return getQuarterMetricTotal(measurables, calculateTotal);
  };

  const openPrefilledAddMetricDialog = () => {
    handleOpenAddMetricDialog({
      definedPlan: _.get(oneYearPlanData, "plan.id"),
      definedUsers: form.users,
      definedUnit: form.unit,
      definedValue: form.value,
      definedCategory: "quarter",
      definedVariables: { planId: _.get(oneYearPlanData, "plan.id"), organization: params.org },
    });
  };

  const handleComparator = (event, newComparator) => {
    // enforce value set
    if (newComparator !== null) {
      handleChangeManual({ name: "comparator", value: newComparator });
    }
  };

  const handleCreateQuarterMetric = () => {
    const plans = _.get(data, "plans", []);
    const threeYearPlan = _.find(plans, { id: form.plan });

    if (oneYearPlanData) {
      openPrefilledAddMetricDialog();
    } else {
      getOneYearPlan({
        variables: {
          organization: params.org,
          sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
        },
      });

      setOpenAddMetricDialog(true);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const {
      value,
      users,
      unit,
      comparator,
      measurables,
      cumulative,
      calculateTotal,
      plan,
      objectives,
      enableFormula,
      formula,
      formulaScope,
      weeklyTarget,
      threeYearMetric,
    } = form;

        // 5th value is the total for all quarters
    if (category === "1 year") { 
      if (cumulative) {
        measurables[4][1].value = measurables[3][1].value.toString(); //Year total is the same as the 4th quarter total

        // measurables[4][2].value = measurables.reduce((val, arr) => arr[2].value || val, null);
        measurables[4][2].value = "";
      }

      if (selectedThreeYearMetric) {
        //Update the distribution in the 3 year metric, if it's changed.
        const orignalDistribution = _.get(selectedThreeYearMetric, ["measurables", selectedThreeYearMetricYearIndex.toString(), "1", "distribution"],[]);
        if (!_.isNil(orignalDistribution) && !_.isEqual(orignalDistribution, selectedThreeYearMetricDistribution)) {
          //if the distribution has been changed by the user, update the selectedThreeYearMetric to reflect this

          // remove __typename from the measurable objects
          const measurablesClone = _.cloneDeep(_.get(selectedThreeYearMetric, ["measurables"])).map((array) =>
            array.map((obj) => ({
              value: obj.value,
              notes: obj.notes,
              reference: _.get(obj, "reference.id"),
              distribution: obj.distribution,
            }))
          );
          const newMeasurables = _.set(measurablesClone, [`${selectedThreeYearMetricYearIndex}`, "1", "distribution"], selectedThreeYearMetricDistribution);

          try {
            updateMetric({
              variables: {
                id: selectedThreeYearMetric.id,
                measurables: newMeasurables,
              },
            });           
          }   
          catch (e) {
            console.log(e);
          }     
        }

      }
    }

    measurables.forEach((m) => {
      m[1].value = removeFormattedNum(m[1].value);
      m[2].value = removeFormattedNum(m[2].value);

      m.forEach((obj) => {
        obj.notes = (obj.notes || []).map((note) => note.id);
      });
    });

    const ok = await updateMetric({
      variables: {
        id,
        value,
        users,
        plan,
        objectives,
        unit,
        comparator,
        measurables,
        cumulative,
        calculateTotal,
        enableFormula,
        formula,
        formulaScope,
        weeklyTarget,
        threeYearMetric,
      },
    });

    if (ok) {
      snack(`Updated "${value}" metric`);
      handleClose();
    }
  };

  useEffect(() => {
    if (category === "1 year") {
      getThreeYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
      getWeeklyTargets({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
    }
  }, [category]);

  useEffect(() => {
    if ((category === "1 year") && (refetchThreeYearMetrics || open)) {
      threeYearMetricsRefetch({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });

      setRefetchThreeYearMetrics(false);
    }
  }, [refetchThreeYearMetrics, open]);

  useEffect(() => { 
    //this removes the __typename from the form's measurables
    const newMeasurables = measurables.map((array) =>
      array.map((obj) => ({ value: obj.value, notes: obj.notes, reference: _.get(obj, "reference.id"), distribution: obj.distribution }))
    );

    handleChangeManual({ name: `measurables`, value: newMeasurables });
  }, [measurables]);


  useEffect(() => {
    if (category === "3 year") {
      getOneYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
        },
      });
    }
    else {
      const corpPlan = _.get(plan,"plan.id",_.get(plan,"id"),null);
      getOneYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId,
          corpPlan
        },
      });      
    }
  }, [category]);

  useEffect(() => {
    if (oneYearMetricsData) {
      setOneYearMetrics(_.get(oneYearMetricsData, "metrics", []));
    }
  }, [oneYearMetricsData]);

  useEffect(() => {
    if (threeYearMetricsData) {
      if (category === "3 year") {
        setThreeYearMetrics(_.get(threeYearMetricsData, "metrics", []));
      }
      else if (oneYearMetrics) {

        const linkedThreeYearIdsToFilterList = oneYearMetrics
        .filter(metric => metric.id !== id) //remove the current 1YMetric from consideration
        .map(metric => metric.threeYearMetric)  // Extract the threeYearMetric value
        .filter(metricId => metricId && metricId.trim() !== '' && metricId !== form.threeYearMetric);
        
        const allMetrics = _.get(threeYearMetricsData, "metrics", []);
        const filteredMetrics = allMetrics.filter(metric => !linkedThreeYearIdsToFilterList.includes(metric.id));

        setThreeYearMetrics(filteredMetrics);
      }
    }
  }, [threeYearMetricsData, oneYearMetrics, form.threeYearMetric]);

  useEffect(() => {
    if (weeklyTargetsData) {
      setWeeklyTargets(_.get(weeklyTargetsData, "weeklyTargets", []));
    }
  }, [weeklyTargetsData]);

  useEffect(() => {
    if (oneYearPlanData && openAddMetricDialog) {
      setOpenAddMetricDialog(false);
      openPrefilledAddMetricDialog();
    }
  }, [oneYearPlanData]);

  useEffect(() => {
    if (oneYearPlansRefetch) {
      const plans = _.get(data, "plans", []);
      const threeYearPlan = _.find(plans, { id: form.plan });
      oneYearPlansRefetch({
        organization: params.org,
        sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
      });
    }
  }, [form.plan]);

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  useEffect(() => {
    refetch();
  }, [fetch]);

  useEffect(() => {
    if (form.calculateTotal) {
      updateTotal(1)(form);
    }
  }, [form.calculateTotal]);

  if (dialogDataLoading) return null;

  const isOneYear = category === "1 year";
  const numOfvariables = _.get(form, "formulaScope.length");
  const currentVariable = _.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
    varType: "reference",
    value: null,
  });

  const selectedWeeklyTarget = weeklyTargets.find((wt) => wt.id === form.weeklyTarget);



  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>Edit Metric</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Metric Details"
            name="value"
            fullWidth
            multiline
            variant="outlined"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <SelectDepartment
            plans={_.get(data, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText="Which department does this metric belong to?"
          />
          {!isOneYear && (
            <SelectObjectives
              name="objectives"
              objectives={_.get(data, "objectives", null)}
              handleChange={handleChange}
              values={form.objectives}
              category={"1 year"}
              multiple
              helperText="Which 3 year objectives are tied to this metric?"
              plansOrder={plansOrder}
              statuses={["on track", "off track"]}
            />
          )}
          {isOneYear && (
            <>
              <ToggleButtonGroup
                exclusive
                value={form.cumulative}
                onChange={handleChangeToggleButton("cumulative")}
                className={styles.flexCenter}
              >
                <ToggleButton value={false}>
                  <Tooltip title="Each entry is the total from that quarter">
                    <div>Add values</div>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value={true}>
                  <Tooltip title="Each entry includes the totals of previous quarters">
                    <div>Don't add values</div>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {!form.cumulative && (
                <Typography align="center">
                  <Button
                    variant={form.calculateTotal ? "contained" : "outlined"}
                    onClick={handleChangeAuto}
                    color="primary"
                    className={styles.auto}
                    disableElevation
                  >
                    Auto Totals
                  </Button>
                </Typography>
              )}
            </>
          )}
          <SelectUsers
            name="users"
            users={_.get(data, "users")}
            handleChange={handleChange}
            values={form.users}
            helperText="Who is accountable for this metric?"
            plan={_.get(form, "plan")}
            allPlans={_.get(data, "plans")}
          />
          <div className={styles.modelButtons}>
            <ToggleButtonGroup exclusive value={form.comparator} onChange={handleComparator}>
              <ToggleButton value="lt">
                <span className={styles.toggleText}>&#60;</span>
              </ToggleButton>
              <ToggleButton value="lte">
                <span className={styles.toggleText}>&#8804;</span>
              </ToggleButton>
              <ToggleButton value="eq">
                <span className={styles.toggleText}>=</span>
              </ToggleButton>
              <ToggleButton value="gte">
                <span className={styles.toggleText}>&#8805;</span>
              </ToggleButton>
              <ToggleButton value="gt">
                <span className={styles.toggleText}>&#62;</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <FormControlLabel
            control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
            label="Enable Formula"
            style={{ marginLeft: -7 }}
          />
          {form.enableFormula && (
            <div className={styles.formulaInput} style={{ marginBottom: 8 }}>
              <TextField
                placeholder="Formula (e.g. (v0 + 100)/v2)"
                name="formula"
                fullWidth
                margin="dense"
                variant="filled"
                value={form.formula}
                onChange={handleChange}
                helperText={formErrors.formula}
                error={Boolean(formErrors.formula)}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip title="Add Variable">
                        <IconButton onClick={() => setVariableDialogOpen(true)}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Help">
                        <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
              <div>
                {form.formulaScope.map((scope, idx) => {
                  const { varType, value: scopeValue } = scope;
                  const displayName = varType === "number" ? scopeValue : _.get(metricsById, `${scopeValue}.value`, "");
                  return (
                    <Chip
                      key={idx}
                      label={
                        <span>
                          <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                          {`${displayName}`}
                        </span>
                      }
                      variant="outlined"
                      size="small"
                      style={{ marginRight: "4px" }}
                      onDelete={() => {
                        const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                        handleChange({
                          target: {
                            name: "formulaScope",
                            value: newScope,
                          },
                        });
                      }}
                      onClick={() => {
                        setVariableDialogOpen(true);
                        setFormulaScopeIdx(idx);
                      }}
                    />
                  );
                })}
              </div>
              <VariableDialog
                open={variableDialogOpen}
                handleClose={() => {
                  setVariableDialogOpen(false);
                  setFormulaScopeIdx(null);
                }}
                handleChangeVariable={(value) =>
                  handleChange({
                    target: {
                      name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                      value,
                    },
                  })
                }
                formulaScope={currentVariable}
                list={metrics}
                listName={"Metric"}
                createMode={_.isNil(formulaScopeIdx)}
                currentId={id}
              />
              <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
            </div>
          )}
          {isOneYear && !form.enableFormula && (
            <div className={styles.flex}>
              <div className={styles.textFields}>
                <div className={styles.flex}>
                  
                    {!_.isNil(form.threeYearMetric) ? (
                      <>
                        <div className={styles.flex}>
                          <IconButton onClick={handleOpenDistributionSelectedThreeYearMetric()}> 
                            <Icon path={mdiRefresh} size={1} />
                          </IconButton>  
                          <span className={styles.buttonText}>
                            Using <span style={{ fontWeight: "bold" }}>{_.get(selectedThreeYearMetric, "value", "")}</span>{" "}
                          </span>
          
                        </div>                
                      </>
                    ) : (
                      <><span className={styles.buttonText}>Use 3 Year Metric</span></>
                    )}
                  {" "}
                  <IconButton
                    onClick={!_.isNil(form.threeYearMetric) ? () => handleLinkThreeYearMetric(null) : handleOpenThreeYearMetricsList}
                  >
                    <Icon
                      path={!_.isNil(form.threeYearMetric) ? mdiClose : mdiLinkVariant}
                      size={1}
                      color={!_.isNil(form.threeYearMetric) ? red[400] : blueGrey[400]}
                    />
                  </IconButton>
                </div>
              </div>

              <div className={styles.textFields}>
                <div className={styles.flex}>
                  <span className={styles.buttonText}>
                    {!_.isNil(form.weeklyTarget) ? (
                      <>
                        Using KPI <span style={{ fontWeight: "bold" }}>{_.get(selectedWeeklyTarget, "value", "")}</span>
                      </>
                    ) : (
                      <>Use KPI</>
                    )}
                  </span>{" "}
                  <IconButton
                    onClick={!_.isNil(form.weeklyTarget) ? () => handleLinkWeeklyTarget(null) : () => setWeeklyTargetsListOpen(true)}
                  >
                    <Icon
                      path={!_.isNil(form.weeklyTarget) ? mdiClose : mdiLinkVariant}
                      size={1}
                      color={!_.isNil(form.weeklyTarget) ? red[400] : blueGrey[400]}
                    />
                  </IconButton>
                </div>
              </div>
              <span className={styles.textFields}></span>
            </div>
          )}
          {form.measurables.map(([name, projected, actual], i) => {
            const isTotal = name.value === "Total";
            const year = _.get(YEARS, name.value, parseInt(name.value));
            const showLinkBtn = year <= getYear(yearOne) && !form.enableFormula;
            const reference = _.get(actual, "reference");
            const hasReference = !_.isNil(reference);
            const referenceObj = oneYearMetrics.find((m) => m.id === reference);

            if (isTotal && form.cumulative) return null;
            return (
              <div className={styles.flex} key={i}>
                <TextField
                  label={`${name.value} Projected Value`}
                  name={`measurables[${i}][1].value`}
                  variant="outlined"
                  value={form.enableFormula ? "TBD" : projected.value}
                  onChange={handleChangeCallback(updateTotal(1))}
                  margin="normal"
                  className={styles.textFields}
                  disabled={(isTotal && form.calculateTotal) || form.enableFormula || form.threeYearMetric}
                  helperText={_.get(formErrors, `measurables[${i}][1].value`)}
                  error={Boolean(_.get(formErrors, `measurables[${i}][1].value`))}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: !isOneYear && (
                      <IconButton onClick={handleOpenDistribution(year, i)}>
                        <Icon path={mdiRefresh} size={1} />
                      </IconButton>
                    )
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {((!isOneYear && hasReference) || (isOneYear && form.weeklyTarget)) && !form.enableFormula ? (
                  <>
                    <span className={styles.referenceText}>
                      Data pulled from{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {isOneYear ? _.get(selectedWeeklyTarget, "value", "") : _.get(referenceObj, "value", "")}
                      </span>
                    </span>
                    <span className={styles.referenceText}></span>
                  </>
                ) : (
                  <>
                    <TextField
                      label={`${name.value} Actual Value`}
                      name={`measurables[${i}][2].value`}
                      variant="outlined"
                      // value={hasReference ? getOneYearMetricTotal(reference) : form.enableFormula ? "TBD" : actual.value}
                      value={form.enableFormula ? "TBD" : actual.value}
                      onChange={handleChangeCallback(updateTotal(2))}
                      margin="normal"
                      className={styles.textFields}
                      disabled={(isTotal && form.calculateTotal) || hasReference || form.enableFormula || !_.isEmpty(form.weeklyTargets)}
                      helperText={_.get(formErrors, `measurables[${i}][2].value`)}
                      error={Boolean(_.get(formErrors, `measurables[${i}][2].value`))}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Unit"
                      name="unit"
                      variant="outlined"
                      margin="normal"
                      value={form.unit || ""}
                      onChange={handleChange}
                      className={styles.textFields}
                      disabled={isTotal && form.calculateTotal}
                      InputLabelProps={{
                        shrink: true,
                      }}                     
                    />
                  </>
                )}
                {!isOneYear && (
                  <Tooltip title="Link 1 year metric">
                    <IconButton
                      onClick={handleOpenOneYearMetrics(year, i)}
                      style={{ visibility: showLinkBtn ? "visible" : "hidden" }}
                      disabled={!showLinkBtn}
                    >
                      <Icon path={mdiLinkVariant} size={1} color={hasReference ? deepPurple[400] : blueGrey[400]} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {oneYearMetricsListOpen && (
        <OneYearMetricsList
          open={oneYearMetricsListOpen}
          onClose={() => setOneYearMetricsListOpen(false)}
          metrics={oneYearMetrics}
          selectedYear={selectedYear}
          closed={selectedYear < getYear(yearOne)}
          onSubmit={handleLinkOneYearMetric}
          formMetric={_.get(form, ["measurables", selectedMeasurableIdx.toString(), "2", "reference"])}
          plansOrder={plansOrder}
          handleCreateQuarterMetric={handleCreateQuarterMetric}
        />
      )}
      {distributionOpen && (
        <Distribution
          open={distributionOpen}
          onClose={() => setDistributionOpen(false)}
          selectedYear={selectedYear}
          handleSubmit={handleChangeDistribution}
          distribution={_.get(form, ["measurables", selectedMeasurableIdx.toString(), "1", "distribution"])}
          projectedTotal={_.get(form, ["measurables", selectedMeasurableIdx.toString(), "1", "value"])}
        />
      )}
      {selectedThreeYearMetricDistributionOpen && (
        <Distribution
          open={selectedThreeYearMetricDistributionOpen}
          onClose={() => setSelectedThreeYearMetricDistributionOpen(false)}
          selectedYear={getYear(yearOne)}
          handleSubmit={handleChangeDistributionSelectedThreeYearMetric}
          distribution={selectedThreeYearMetricDistribution}
          projectedTotal={selectedThreeYearMetricValue}
        />
      )}      
      {threeYearMetricsListOpen && (
        <ThreeYearMetricsList
          open={threeYearMetricsListOpen}
          onClose={() => setThreeYearMetricsListOpen(false)}
          metrics={threeYearMetrics}
          onSubmit={handleLinkThreeYearMetric}
          formMetric={_.get(form, ["threeYearMetric"])}
          selectedYear={selectedYear}
          updateMetric={updateMetric}
          snack={snack}
        />
      )}
      {weeklyTargetsListOpen && (
        <WeeklyTargetsList
          open={weeklyTargetsListOpen}
          onClose={() => setWeeklyTargetsListOpen(false)}
          weeklyTargets={weeklyTargets}
          onSubmit={handleLinkWeeklyTarget}
          formWeeklyTarget={_.get(form, ["weeklyTarget"])}
        />
      )}
    </>
  );
};

export default EditDialog;

const UPDATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation MetricsEditDialog_UpdateMetric(
    $id: ID!
    $value: String
    $unit: String
    $users: [ID!]
    $plan: ID
    $objectives: [ID!]
    $comparator: String
    $measurables: [[MetricMeasurableInput]]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $weeklyTarget: ID
    $threeYearMetric: ID
  ) {
    updateMetric(
      id: $id
      value: $value
      unit: $unit
      comparator: $comparator
      measurables: $measurables
      users: $users
      plan: $plan
      objectives: $objectives
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      weeklyTarget: $weeklyTarget
      threeYearMetric: $threeYearMetric
    ) {
      metric {
        ...MetricFields
      }
      metrics {
        id
        number
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      plans {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const GET_DIALOG_DATA = gql`
  query MetricsEditDialog_GetDialogData($organization: ID!, $category: String, $sharedPlanId: ID, $oneYearCorpPlan: ID, $closed: Boolean) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $category, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
    }

    objectives(organization: $organization, archived: false, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      status
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;
