import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import styles from "./Metrics.module.scss";
import Icon from "@mdi/react";
import { mdiArrowUpBold, mdiArrowDownBold } from "@mdi/js";

const defaultDistribution = [0.25, 0.25, 0.25, 0.25];

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

const Distribution = ({ open, onClose, selectedYear, distribution, projectedTotal, handleSubmit }) => {
  const [userInputValues, setUserInputValues] = useState(defaultDistribution);
  const [distributionValues, setDistributionValues] = useState(defaultDistribution);
  const [annualTotal, setAnnualTotal] = useState(projectedTotal);

  const classes = useStyles();
  const decimalDigits = 10;

  useEffect(() => {
    setDistributionValues(!_.isNil(distribution) && !_.isEmpty(distribution) ? distribution : defaultDistribution);
    setUserInputValues(!_.isNil(distribution) && !_.isEmpty(distribution) ? distribution : defaultDistribution);
  }, [distribution]);

  // Debounced calculation function to update distribution values based on user input
  const debouncedUpdateDistribution = useCallback(
    _.debounce((values) => {
      const normalizedValues = normalizeDistribution(values);
      setDistributionValues(normalizedValues);
    }, 1000),
    []
  );

  const normalizeDistribution = (values) => {
    /*let total = _.sum(values);
    if (total === 0) return values; // Avoid division by zero
    let normalizedValues = values.map(value => value / total);
  
    // Ensure the total is exactly 1.0 by adjusting the last value slightly
    const error = 1.0 - _.sum(normalizedValues);
    if (Math.abs(error) > 0.000001) {
      normalizedValues[normalizedValues.length - 1] += error;
      console.log("Dist Error Value was: ",error);
    }
  
    return normalizedValues.map(value => _.round(value, 2));  // Round to 2 decimal places
    */
    return values.map(value => _.round(value, 2))
  };

  const handlePercentageChange = (index, value) => {
    const newInputValues = [...userInputValues];
    newInputValues[index] = value;

    setUserInputValues(newInputValues);

    // Trigger debounced update
    const numericValues = newInputValues.map(v => _.round(Number(v), decimalDigits));
    debouncedUpdateDistribution(numericValues);
  };

  const handleAmountChange = (index, value) => {
    if (!annualTotal) return;

    const newInputValues = [...userInputValues];
    newInputValues[index] = _.round(Number(value) / annualTotal, decimalDigits);

    setUserInputValues(newInputValues);

    // Trigger debounced update
    const numericValues = newInputValues.map(v => _.round(Number(v), decimalDigits));
    debouncedUpdateDistribution(numericValues);
  };

  const total = _.round(_.sum(distributionValues), 2);
  const currentAnnualTotal = distributionValues.reduce(
    (accumulator, currentValue) => accumulator + currentValue * annualTotal,
    0
  );
  const zeroTotal = _.isNil(annualTotal) || !annualTotal;
  console.log('Re-render Distribution Values:' )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Quarter Projected Distribution ({selectedYear})
        <Typography variant="subtitle1">
          Projected Annual Value: {!zeroTotal ? _.round(annualTotal, 2) : <i>Not Set</i>}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Distribute by Percentage:</Typography>
        <div className={styles.distributionContainer}>
          {userInputValues.map((value, idx) => (
            <TextField
              required
              key={idx}
              label={`Q${idx + 1}`}
              name={`${idx}`}
              variant="outlined"
              margin="dense"
              value={value}  // Keep the value exactly as typed
              type="number"
              onChange={(e) => handlePercentageChange(idx, e.target.value)}
              helperText=" "
              inputProps={{ style: { fontSize: 12 } }}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ))}
          <TextField
            disabled
            error={total !== 1}
            label={`Total %`}
            variant="outlined"
            margin="dense"
            value={total}
            type="number"
            helperText=" "
            inputProps={{ style: { fontSize: 12, fontWeight: 'bold', color: 'black' } }}
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Typography variant="subtitle1">Distribute by Value:</Typography>
        <div className={styles.distributionContainer}>
          {userInputValues.map((value, idx) => (
            <TextField
              disabled={zeroTotal || true}
              required
              key={idx}
              name={`${idx}`}
              variant="outlined"
              margin="dense"
              value={!zeroTotal ? _.round(value * annualTotal, decimalDigits).toFixed(2) : ''}
              type="number"
              onChange={(e) => handleAmountChange(idx, e.target.value)}
              helperText=" "
              inputProps={{ style: { fontSize: 12 } }}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ))}
          <TextField
            disabled
            error={total !== 1}
            label={`Total Value`}
            variant="outlined"
            margin="dense"
            value={!zeroTotal ? _.round(currentAnnualTotal, decimalDigits) : ''}
            type="number"
            helperText={
              !zeroTotal && total !== 1 ? (
                <>
                  <Icon
                    path={currentAnnualTotal < annualTotal ? mdiArrowUpBold : mdiArrowDownBold}
                    size="1em"
                  />
                  {_.round(Math.abs(currentAnnualTotal - annualTotal), decimalDigits)}
                </>
              ) : " "
            }
            inputProps={{ style: { fontSize: 12, fontWeight: 'bold', color: 'black' } }}
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(distributionValues)} color="primary" disabled={total !== 1}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Distribution;
