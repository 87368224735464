import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import handleViewport from "react-in-viewport";
import styles from "./Objectives.module.scss";
import _ from "lodash";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useInViewport from "../../hooks/useInViewport";
import { getYear } from "../../utils/dates";
import { mergeCustomizer } from "../../utils/misc";

// import { LoadingContext } from "../../context/loadingContext";

import { TableRow, TableCell, Typography, IconButton } from "@material-ui/core";

import ProgressBarRocks from "../ProgressBar/ProgressBarRocks";
import Skeleton from "./Skeleton";
import { mdiChevronLeftCircleOutline, mdiChevronRightCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

const RockProgressTotals = ({
  inViewport,
  forwardedRef,
  objectives,
  category,
  threeYearTieIn,
  quarterNumber,
  showAllQuarters,
  handleToggleQuarter,
  fiscalYear,
}) => {
  const params = useParams();
  const hasRendered = useInViewport(inViewport);

  const progressBarObjs = useMemo(() => {
    let retObjs = objectives;
    // if applying for the 3 year objectives, then need to extract the inner 1 year objectives of the 3 year objectives
    if (category === "3 year") {
      retObjs = _.flatten(objectives.map((obj) => _.get(obj, "objectives", [])));
    }

    return retObjs;
  }, [objectives, category]);

  if (!hasRendered) {
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton long={category === "3 year"} />
      </TableRow>
    );
  }

  return (
    <TableRow ref={forwardedRef} className={styles.noBot}>
      <TableCell align="center"/>
      <TableCell align="center"/>
      <TableCell />
      {category === "3 year" && <TableCell />}
      <TableCell />
      <TableCell align="center">
        <ProgressBar
          objectives={progressBarObjs}
          organization={params.org}
          category={category}
          threeYearTieIn={threeYearTieIn}
          quarterNumber={quarterNumber}
          showAllQuarters={showAllQuarters}
          handleToggleQuarter={handleToggleQuarter}
          fiscalYear={fiscalYear}
        />
      </TableCell>
      {category === "1 year" && <TableCell align="center" />}
      <TableCell align="right" />
    </TableRow>
  );
};

export default handleViewport(RockProgressTotals);

const ProgressBar = ({
  organization,
  objectives,
  category,
  threeYearTieIn,
  quarterNumber,
  showAllQuarters,
  handleToggleQuarter,
  fiscalYear,
}) => {
  const [rockProgressData, setRockProgressData] = useState(() => (category === "3 year" ? [{ rocks: [] }] : []));
  // const { updateLoading } = useContext(LoadingContext);

  const [getObjectives, { loading: rockProgTotalsLoading, data, refetch }] = useLazyQuery(GET_OBJS_AND_CORP_PLANS, {
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions,
  });

  useEffect(() => {
    if (category === "3 year") {
      const variables = { organization: organization, ids: objectives };
      // if (refetch) {
      //   refetch(variables);
      // } else {
      getObjectives({ variables });
      // }
    } else {
      setRockProgressData(_.flatten(objectives.map((obj) => _.get(obj, "rocks", []))));
    }
  }, [category, objectives]);

  useEffect(() => {
    if (data && category === "3 year") {
      const oneYearObjs = _.get(data, "objectives", []);
      const corpObjs = oneYearObjs.filter((obj) => _.get(obj, "plan.departmentName") === "Corporate");
      const nonCorpObjs = _.difference(oneYearObjs, corpObjs);

      const objsByCorp1 = _.groupBy(corpObjs, "plan.id");
      const objsByCorp2 = _.groupBy(nonCorpObjs, "plan.plan.id");
      const objsByCorp = _.mergeWith(objsByCorp1, objsByCorp2, mergeCustomizer);

      let rocksByCorp = {};
      for (const corpId in objsByCorp) {
        rocksByCorp[corpId] = _.flatten(objsByCorp[corpId].map((obj) => _.get(obj, "rocks", [])));
      }

      setRockProgressData(
        _.get(data, "plans", []).reduce((prev, { id, year }) => {
          const isCurrentYear = year === fiscalYear;
          prev.push({ isCurrent: isCurrentYear, year: getYear(year), rocks: _.get(rocksByCorp, id, []) });
          return prev;
        }, [])
      );
    }
  }, [data]);

  // useEffect(() => {
  //   if (!_.isNil(rockProgTotalsLoading)) updateLoading({ loadingObj: { rockProgTotalsLoading } });
  // }, [rockProgTotalsLoading]);

  const getCurrentYearProgress = (rocks, key) => {
    let totalRocksDone = 0;
    rocks.forEach((r) => {
      if (r.status === "complete") totalRocksDone++;
    });
    const rocksByQuarter = _.groupBy(rocks, "index");

    return (
      <React.Fragment key={key}>
        {showAllQuarters ? (
          <>
            <ProgressBarRocks rocks={rocksByQuarter[1]} tooltipTitle="Q1" />
            <ProgressBarRocks rocks={rocksByQuarter[2]} tooltipTitle="Q2" />
            <ProgressBarRocks rocks={rocksByQuarter[3]} tooltipTitle="Q3" />
            <ProgressBarRocks rocks={rocksByQuarter[4]} tooltipTitle="Q4" />
            <Typography variant="body2" className={styles.rocksCompleteText}>
              {totalRocksDone}/{rocks.length}
            </Typography>            
          </>
        ) : (
          <>
            <IconButton onClick={() => handleToggleQuarter(-1)} disabled={quarterNumber === 0}>
              <Icon path={mdiChevronLeftCircleOutline} size={1} />
            </IconButton>
            <ProgressBarRocks rocks={rocksByQuarter[quarterNumber + 1]} tooltipTitle={`Q${quarterNumber + 1}`} />
            <IconButton onClick={() => handleToggleQuarter(1)} disabled={quarterNumber === 3}>
              <Icon path={mdiChevronRightCircleOutline} size={1} />
            </IconButton>
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.flexCenter}>
      {category === "3 year"
        ? _.sortBy(rockProgressData, ["year"]).map(({ isCurrent, year, rocks }, idx) => {
            return !isCurrent
              ? threeYearTieIn === "all" && <ProgressBarRocks alt={true} key={idx} rocks={rocks} tooltipTitle={`${year - 1} - ${year}`} />
              : getCurrentYearProgress(rocks, idx);
          })
        : getCurrentYearProgress(rockProgressData)}
    </div>
  );
};

const GET_OBJS_AND_CORP_PLANS = gql`
  query RockProgressTotals_GetObjsCorpPlans($organization: ID!, $ids: [ID!]) {
    objectives(organization: $organization, ids: $ids) {
      id
      rocks {
        index
        status
        successCriterias {
          done
        }
      }
      plan {
        id
        year
        departmentName
        plan {
          id
          year
        }
      }
    }

    plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
    }
  }
`;
